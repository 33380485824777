/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Delete, Edit, Payment } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  LocalUrlEnum,
  UrlEnum,
  download,
  paymentTypeEnum,
  titleForReportsTotal,
} from "../../Utils/Utils";
import { BreadCrumbsContext } from "../../Context/breadCrumbsContext";
import { getData } from "../../Services/getData";
import OrderModal from "./OrderModal";
import { deleteData } from "../../Services/deleteData";
import { useLocation, useNavigate } from "react-router-dom";
import StatusOrder from "./StatusOrder";
import moment from "moment";
import theme from "../../Theme/Theme";
import DateFilters from "./DateFilters";
import { BadgeContext } from "../../Context/badgeContext";
import { breadCrumbsEnum, regexIBAN } from "../../Utils/Constants";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import ImportExcelModal from "../../Components/ImportExcelModal";
import { updateData } from "../../Services/updateData";

const fileExtension = ".xlsx";
const fileType =
  "application/vnd.openxml-officedocument.spreadsheetml.sheet;charset=UTF-8";

export default function Orders() {
  const breadCrumbsContext = useContext(BreadCrumbsContext);
  const badgeContext = useContext(BadgeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteIds, setDeleteIds] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const notifyError = (message: string) => toast.error(message);
  const notifySuccess = (message: string) => toast.success(message);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [settings, setSettings] = useState<any>(null);
  const [openImportExcelModal, setOpenImportExcelModal] = useState({
    open: false,
    range: 0,
    name: "",
  });
  const [state, setState] = useState<any>({
    orders: [],
    orderNumber: 0,
    personalDataKeys: [],
    page: 0,
    perPage: 100,
    sort: { name: "createdAt", direction: "desc" },
    filter: "Niciun filtru",
    startDate: moment().subtract(60, "d"),
    endDate: moment(),
    paymentType: null,
    forPayment: false,
    preview: false,
    selectedRequest: {},
    loading: false,
  });
  const userName = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : "";

  /**
   *
   */
  useEffect(() => {
    getOrderStatus();
    getSettings();
  }, []);

  /**
   *
   */
  useEffect(() => {
    breadCrumbsContext.setBreadCrumbs([`${breadCrumbsEnum.orders}`]);
    const encodeSearch = decodeURI(location.search);
    const search = encodeSearch.split(/[?&]/);
    const newState = Object.assign({}, state);
    newState.forPayment = false;
    search.forEach((value) => {
      if (value !== "") {
        const values = value.split("=");
        if (values[0] === "page") {
          newState[values[0]] = +values[1] - 1;
        } else if (values[0] === "sort") {
          const sort = values[1].split(",");
          newState[values[0]] = { name: sort[0], direction: sort[1] };
        } else if (values[0] === "filter") {
          newState[values[0]] = decodeURI(values[1]);
        } else if (values[0] === "forPayment") {
          newState[values[0]] = values[1] === "true" ? true : false;
        } else {
          newState[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        }
      }
    });
    getOrders(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, badgeContext.ordersNumber]);

  /**
   *
   */
  function getOrderStatus() {
    getData(UrlEnum.orderStatus)
      .then((res) => {
        setStatusEnum(res.data);
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   */
  async function getSettings() {
    const res = await getData(UrlEnum.settings);
    if (res && res.data) {
      setSettings(res.data);
    }
  }

  /**
   *
   * @param event
   */
  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  async function getOrders(newState: any) {
    setLoading(true);
    let url = `${UrlEnum.orders}/${newState.page ? newState.page : 0}/${
      newState.perPage ? newState.perPage : state.perPage
    }/${newState.filter ? newState.filter : state.filter}/${
      newState.sort.name ? newState.sort.name : newState.sort.name
    },${
      newState.sort.direction ? newState.sort.direction : state.sort.direction
    }/${newState.startDate ? newState.startDate : state.startDate}/${
      newState.endDate ? newState.endDate : newState.endDate
    }/${newState.paymentType ? newState.paymentType : state.paymentType}`;
    if (newState.search) {
      url += `/${newState.search}`;
    }
    if (newState.forPayment) {
      url = `${UrlEnum.orders}/forPayment/${
        newState.page ? newState.page : 0
      }/${newState.perPage ? newState.perPage : state.perPage}/${
        newState.paymentType ? newState.paymentType : state.paymentType
      }`;
    }
    await getData(url)
      .then((res) => {
        let personalDataKeys = [] as any;
        res.data.orders.forEach((value: any) => {
          personalDataKeys.push(...Object.keys(value.personalData));
        });
        personalDataKeys = [...new Set(personalDataKeys)];
        setState({
          ...state,
          orders: res.data.orders,
          personalDataKeys: personalDataKeys,
          orderNumber: res.data.ordersNumber,
          filter: newState.filter ? newState.filter : state.filter,
          sort: {
            name: newState.sort.name ? newState.sort.name : state.sort.name,
            direction: newState.sort.direction
              ? newState.sort.direction
              : state.sort.direction,
          },
          startDate: moment(
            newState.startDate ? newState.startDate : state.startDate
          ),
          endDate: moment(newState.endDate ? newState.endDate : state.endDate),
          page: newState.page ? newState.page : 0,
          perPage: newState.perPage ? newState.perPage : state.perPage,
          forPayment: newState.forPayment ? newState.forPayment : false,
          paymentType: newState.paymentType ? newState.paymentType : null,
        });
        setLoading(false);
      })
      .catch((err) => {
        notifyError(Vocabulary.getError);
        setLoading(false);
      });
  }

  /**
   *
   */
  async function handleDelete() {
    setLoading(true);
    await deleteData(`${UrlEnum.orders}/${userName}`, {
      data: { ids: deleteIds },
    })
      .then(async (res) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifySuccess(Vocabulary.deleteSuccess);
        await getOrders(state);
      })
      .catch((err) => {
        setLoading(false);
        setOpenDeleteModal(false);
        notifyError(Vocabulary.deleteError);
      });
  }

  /**
   *
   */
  const requestHeaders = [
    {
      label: Vocabulary.numberOrder,
      name: "orderNumber",
      options: {
        customBodyRender: (orderNumber: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {orderNumber}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.deviceName,
      name: "deviceDetails",
      options: {
        sort: true,
        customBodyRender: (deviceDetails: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {deviceDetails.deviceName}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: true,
        customBodyRender: (status: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {status.order}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.paymentType,
      name: "paymentType",
      options: {
        customBodyRender: (paymentType: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {titleForReportsTotal[paymentType]}
            </Typography>
          );
        },
      },
    },
    ...state.personalDataKeys.map((key: string) => ({
      label: key.toUpperCase(),
      name: "personalData",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {value[key]}
            </Typography>
          );
        },
      },
    })),
    state.forPayment
      ? {
          label: "Zile rămase",
          name: "daysSinceStatusChange",
          options: {
            sort: false,
            customBodyRender: (value: any, tableMeta: any) => {
              if (
                state.orders[tableMeta.rowIndex].paymentType ===
                paymentTypeEnum.normal
              )
                return (
                  <Typography style={{ fontWeight: "bold" }}>
                    {2 - value} Zile
                  </Typography>
                );
              if (
                state.orders[tableMeta.rowIndex].paymentType ===
                paymentTypeEnum.consignment
              )
                return (
                  <Typography style={{ fontWeight: "bold" }}>
                    {30 - value} Zile
                  </Typography>
                );
              return null;
            },
          },
        }
      : "",

    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          let fontWeight = "normal";
          if (state.orders[tableMeta.rowIndex].isNew) {
            fontWeight = "bold";
          }
          return (
            <Typography
              style={{ fontWeight: fontWeight, cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `${LocalUrlEnum.orders}${
                    state.orders[tableMeta.rowIndex]._id
                  }`
                );
              }}
            >
              {moment(value).format("DD-MM-YYYY HH:mm")}
            </Typography>
          );
        },
      },
    },
    {
      label: Vocabulary.options.toUpperCase(),
      name: "Optiuni",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10 }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.edit}>
                  <ToggleButton
                    onClick={() => {
                      navigate(
                        `${LocalUrlEnum.orders}${state.orders[rowIndex]._id}`
                      );
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Edit />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={Vocabulary.delete}>
                  <ToggleButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDeleteModal(true);
                      setDeleteIds(state.orders[rowIndex]._id);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <Delete />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: [10, 20, 50, 100, 200],
      confirmFilters: false,
      filter: false,
      print: false,
      download: false,
      sortOrder: {
        name: state.sort.name,
        direction: state.sort.direction,
      } as any,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      count: state.orderNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: true,
      serverSide: true,
      sort: true,
      customToolbar: () => (
        <>
          {/* Render Your Custom Payment Icon Here */}
          {components.icons.CustomPaymentIcon()}
        </>
      ),
      customToolbarSelect: (selectedRows: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label
              style={{
                margin: "0px 5px",
                color: "red",
                cursor: "pointer",
                verticalAlign: "middle",
              }}
              onClick={() => downloadPdf(selectedRows)}
            >
              {Vocabulary.downloadPdf}
            </label>
            <PictureAsPdfIcon
              style={{ margin: "0px 5px", color: "red", cursor: "pointer" }}
              onClick={() => downloadPdf(selectedRows)}
            />
            <label
              style={{
                margin: "0px 0px 0px 15px",
                color: "blue",
                cursor: "pointer",
                verticalAlign: "middle",
              }}
              onClick={handleMenuOpen}
            >
              {Vocabulary.payments}
            </label>
            <IconButton onClick={handleMenuOpen} style={{ color: "blue" }}>
              <Payment />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => exportTXTING(selectedRows)}>
                {Vocabulary.exportXLSING}
              </MenuItem>
              <MenuItem onClick={() => exportExcelRaiffeisen(selectedRows)}>
                {Vocabulary.exportXLSRaiffeisen}
              </MenuItem>
              <MenuItem
                disabled
                onClick={() =>
                  setOpenImportExcelModal({
                    open: true,
                    range: 0,
                    name: "ING",
                  })
                }
              >
                {Vocabulary.importCSVStatementsING}
              </MenuItem>
              <MenuItem
                disabled
                onClick={() =>
                  setOpenImportExcelModal({
                    open: true,
                    range: 17,
                    name: "Raiffeisen",
                  })
                }
              >
                {Vocabulary.importCSVStatementsRaiffeisen}
              </MenuItem>
            </Menu>
            <Delete
              style={{ margin: "0px 10px", cursor: "pointer" }}
              onClick={() => deleteMultiple(selectedRows)}
            />
          </div>
        );
      },
      onRowClick: (rowData: any, rowState: any) => {
        navigate(
          `${LocalUrlEnum.orders}${state.orders[rowState.rowIndex]._id}`
        );
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        const status = statusEnum.find(
          (stEnum: any) =>
            stEnum.name.toLowerCase() ===
            state.orders[rowIndex].status.order.toLowerCase()
        );
        if (status) {
          if (
            state.orders[rowIndex].paymentType === paymentTypeEnum.consignment
          )
            return {
              style: {
                backgroundColor: status.color,
                borderLeft: "5px solid rgba(81,158,0,1)",
                cursor: "pointer",
              },
            };
          return {
            style: {
              backgroundColor: status.color,
              borderLeft: `5px solid ${status.color}`,
              cursor: "pointer",
            },
          };
        }
        if (state.orders[rowIndex].status.order === "Contact cu lichide")
          return {
            style: {
              backgroundColor: theme.palette.grayColor?.main,
              borderLeft: "5px solid #C60000",
              cursor: "pointer",
            },
          };
        return {
          style: {
            backgroundColor: theme.palette.grayColor?.main,
            cursor: "pointer",
          },
        };
      },
      onChangePage: (page: any) => {
        if (state.forPayment) {
          if (state.search)
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?forPayment=${state.forPayment}&paymentType=${
                state.paymentType
              }?search=${state.search}`
            );
          else
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?forPayment=${state.forPayment}&paymentType=${state.paymentType}`
            );
        } else {
          if (state.search)
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}?search=${state.search}`
            );
          else
            navigate(
              `${LocalUrlEnum.orders}?page=${page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}`
            );
        }
        window.scrollTo(0, 0);
      },
      /**
       *
       * @param numberOfRows
       */
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (state.forPayment) {
          if (state.search)
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?forPayment=${
                state.forPayment
              }&paymentType=${state.paymentType}?search=${state.search}`
            );
          else
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?forPayment=${
                state.forPayment
              }&paymentType=${state.paymentType}`
            );
        } else {
          if (state.search)
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?filter=${state.filter}?sort=${
                state.sort.name
              },${state.sort.direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}?search=${state.search}`
            );
          else
            navigate(
              `${
                LocalUrlEnum.orders
              }?page=${1}?perPage=${numberOfRows}?filter=${state.filter}?sort=${
                state.sort.name
              },${state.sort.direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}`
            );
        }
        window.scrollTo(0, 0);
      },
      /**
       *
       * @param search
       */
      onSearchChange: async (search: string | null) => {
        if (state.forPayment) {
          //
        } else {
          if (search) {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}?search=${search}`
            );
          } else {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${state.filter}?sort=${state.sort.name},${
                state.sort.direction
              }?startDate=${moment(state.startDate).format(
                "YYYY-MM-DD"
              )}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}`
            );
          }
        }
      },
      /**
       *
       */
      onSearchClose: async () => {
        if (state.forPayment) {
          //
        } else {
          navigate(
            `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
              state.perPage
            }?filter=${state.filter}?sort=${state.sort.name},${
              state.sort.direction
            }?startDate=${moment(state.startDate).format(
              "YYYY-MM-DD"
            )}?endDate=${moment(state.endDate).format(
              "YYYY-MM-DD"
            )}&paymentType=${state.paymentType}`
          );
        }
      },
      onColumnSortChange: async (changedColumn: string, direction: string) => {
        if (state.forPayment) {
          //
        } else {
          if (state.search) {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${
                state.filter
              }?sort=${changedColumn},${direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}?search=${state.search}`
            );
          } else {
            navigate(
              `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                state.perPage
              }?filter=${
                state.filter
              }?sort=${changedColumn},${direction}?startDate=${moment(
                state.startDate
              ).format("YYYY-MM-DD")}?endDate=${moment(state.endDate).format(
                "YYYY-MM-DD"
              )}&paymentType=${state.paymentType}`
            );
          }
        }
      },
    };
  }

  const components = {
    icons: {
      CustomPaymentIcon: () => (
        <>
          <label
            style={{
              margin: "0px 0px 0px 15px",
              color: "blue",
              cursor: "pointer",
              verticalAlign: "middle",
            }}
            onClick={handleMenuOpen}
          >
            {Vocabulary.payments}
          </label>
          <IconButton onClick={handleMenuOpen} style={{ color: "blue" }}>
            <Payment />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled onClick={() => exportTXTING([])}>
              {Vocabulary.exportXLSING}
            </MenuItem>
            <MenuItem disabled onClick={() => exportExcelRaiffeisen([])}>
              {Vocabulary.exportXLSRaiffeisen}
            </MenuItem>
            <MenuItem
              onClick={() =>
                setOpenImportExcelModal({
                  open: true,
                  range: 0,
                  name: "ING",
                })
              }
            >
              {Vocabulary.importCSVStatementsING}
            </MenuItem>
            <MenuItem
              onClick={() =>
                setOpenImportExcelModal({
                  open: true,
                  range: 17,
                  name: "Raiffeisen",
                })
              }
            >
              {Vocabulary.importCSVStatementsRaiffeisen}
            </MenuItem>
          </Menu>
        </>
      ),
    },
  } as any;

  /**
   *
   * @param text
   * @returns
   */
  function cleanText(text: string) {
    return text
      ? text.toString().replace(/[.,!?"'`~@#$%^&*(){}[\]:;<>\\/+=_-]/g, "")
      : text;
  }

  /**
   *
   * @param selectedRows
   */
  function exportTXTING(selectedRows: any) {
    setLoading(true);

    const data: any = [];
    for (let i = 0; i < selectedRows.data.length; i++) {
      const order = state.orders[selectedRows.data[i].dataIndex];
      const testRegexIBAN = new RegExp(regexIBAN);
      if (testRegexIBAN.test(order.paymentInfo["Nr. contul IBAN"])) {
        data.push([
          cleanText(order.paymentInfo["Nume complet titular cont"]),
          cleanText(order.paymentInfo["Nr. contul IBAN"]),
          cleanText(order.estimatedPrice.toString()), // Ensure number is a string
          cleanText(`cf. cu comanda de vanzare ${order.orderNumber}`),
          "",
        ]);
      }
    }

    // Convert data to TXT format with TAB as separator
    const txtContent = data
      .map((row: any) => row.join("\t")) // Use TAB instead of comma
      .join("\n"); // Join rows with newlines

    // Create a TXT file and trigger download
    const fileName = `Export_ING_${moment().format("DD-MM-YYYY_HH-mm-ss")}.txt`;
    const blob = new Blob([txtContent], { type: "text/plain;charset=utf-8;" });
    FileSaver.saveAs(blob, fileName);

    setLoading(false);
  }

  /**
   *
   */
  function exportExcelRaiffeisen(selectedRows: any) {
    setLoading(true);
    const columns = [
      "IBAN Platitor",
      "IBAN Beneficiar",
      "Suma",
      "Moneda (Lei)",
      "Nume beneficiar (maxim 40 de caractere)",
      "Descriere (maxim 70 de caractere)",
      "Numar ordin de plata (doar pentru platile interbancare sau catre trezorerie)",
      "Numar identificare beneficiar (CNP sau CUI, doar pentru platile catre trezorerie)",
      "Numar de evidenta (doar catre platile catre trezorerie, optional)",
    ];
    const data: any = [];
    for (let i = 0; i < selectedRows.data.length; i++) {
      const order = state.orders[selectedRows.data[i].dataIndex];
      const testRegexIBAN = new RegExp(regexIBAN);
      if (testRegexIBAN.test(order.paymentInfo["Nr. contul IBAN"])) {
        data.push([
          cleanText(settings?.accountRaiffeisen || ""),
          cleanText(order.paymentInfo["Nr. contul IBAN"]),
          cleanText(order.estimatedPrice),
          "Lei",
          cleanText(order.paymentInfo["Nume complet titular cont"]),
          `cf. cu comanda de vanzare ${order.orderNumber}`,
          "",
          "",
          "",
        ]);
      }
    }
    const exportData = [];
    for (let i = 0; i < data.length; i++) {
      const raw: any = {};
      for (let k = 0; k < columns.length; k++) {
        raw[columns[k]] = data[i][k];
      }
      exportData.push(raw);
    }
    const fileName = `Export_Raiffeisen_${moment().format(
      "DD-MM-YYYY_HH-mm-ss"
    )}`;
    const ws = XLSX.utils.json_to_sheet(exportData);
    const sheetsName = ["sheet1"];

    const wb = { Sheets: { sheet1: ws }, SheetNames: sheetsName };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const orders = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(orders, fileName + fileExtension);
    setLoading(false);
  }

  /**
   *
   * @param selectedRows
   */
  function downloadPdf(selectedRows: any) {
    setLoading(true);
    const ids = selectedRows.data.map(
      (d: any) => state.orders[d.dataIndex]._id
    );

    const token = localStorage.getItem("access_token");
    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderIds: ids }),
    };
    const timestamp = Date.now();
    const filename = `Orders_${timestamp}.pdf`;
    fetch(`${UrlEnum.documents}/generatePdf`, options)
      .then((response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        let suggestedFilename: any = filename;
        if (contentDisposition) {
          const filenameMatch: any =
            contentDisposition.match(/filename="?(.+)""?/);
          if (filenameMatch.length > 1) {
            suggestedFilename = filenameMatch[1];
          }
        }
        if (response.status === 200) {
          response.blob().then((blob: Blob) => {
            download(blob, suggestedFilename);
            setLoading(false);
            deletePDF(suggestedFilename);
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /**
   *
   * @param fileName
   */
  async function deletePDF(fileName: string) {
    await deleteData(`${UrlEnum.documents}/deletePdf/${fileName}`);
  }
  /**
   *
   * @param rowsDeleted
   */
  function deleteMultiple(rowsDeleted: any) {
    const ids = rowsDeleted.data.map((d: any) => state.orders[d.dataIndex]._id);
    setDeleteIds(ids);
    setOpenDeleteModal(true);
  }
  /**
   *
   * @param key
   * @param value
   */
  function handleChangeStatus(
    id: string | null | undefined,
    value: string | undefined
  ) {
    setState({ ...state, filter: value });
    if (state.search)
      navigate(
        `${LocalUrlEnum.orders}?page=1?perPage=${
          state.perPage
        }?filter=${value}?sort=${state.sort.name},${
          state.sort.direction
        }?startDate=${moment(state.startDate).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}&paymentType=${
          state.paymentType
        }?search=${state.search}`
      );
    else
      navigate(
        `${LocalUrlEnum.orders}?page=1?perPage=${
          state.perPage
        }?filter=${value}?sort=${state.sort.name},${
          state.sort.direction
        }?startDate=${moment(state.startDate).format(
          "YYYY-MM-DD"
        )}?endDate=${moment(state.endDate).format("YYYY-MM-DD")}&paymentType=${
          state.paymentType
        }`
      );
  }

  /**
   *
   * @param data
   */
  async function getDataFromImportExcel(data: any) {
    let orderNumbers: string[] = [];
    data.forEach((item: any) => {
      if (item.detaliiTranzactie) {
        if (item.detaliiTranzactie.includes("cf. cu comanda de vanzare")) {
          const words = item.detaliiTranzactie.split(" ");
          orderNumbers.push(...words.filter((word: string) => !isNaN(+word)));
        }
      }

      if (item.descriereaTranzactiei) {
        if (item.descriereaTranzactiei.includes("cf. cu comanda de vanzare")) {
          const words = item.descriereaTranzactiei.split(" ");
          orderNumbers.push(...words.filter((word: string) => !isNaN(+word)));
        }
      }
    });
    orderNumbers = orderNumbers.filter((item: any) => item !== "");
    setLoading(true);
    await updateData(`${UrlEnum.orders}/markOrdersAsPaid`, {
      orderNumbers: orderNumbers,
    });
    setOpenImportExcelModal({
      open: false,
      range: 0,
      name: "",
    });
    await getOrders(state);
    setLoading(false);
  }

  return (
    <>
      <ToastContainer />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <DateFilters state={state} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 25,
                height: 25,
                background: "linear-gradient(90deg,#FF7A7A 0%,#C60000 100%)",
                borderRadius: "4px",
              }}
            ></div>
            <p style={{ marginLeft: 10 }}>Contact cu lichide</p>
          </div>
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 25,
                height: 25,
                background:
                  "linear-gradient(90deg,rgba(145,201,85,1) 0%,rgba(81,158,0,1) 100%)",
                borderRadius: "4px",
              }}
            ></div>
            <p style={{ marginLeft: 10 }}>Plata in 30 zile</p>
          </div>
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={state.forPayment}
              onChange={(e) => {
                if (e.target.checked) {
                  navigate(
                    `${LocalUrlEnum.orders}?page=1?perPage=${state.perPage}?forPayment=${e.target.checked}&paymentType=${state.paymentType}`
                  );
                } else {
                  navigate(
                    `${LocalUrlEnum.orders}?page=${state.page + 1}?perPage=${
                      state.perPage
                    }?filter=${state.filter}?sort=${state.sort.name},${
                      state.sort.direction
                    }?startDate=${moment(state.startDate).format(
                      "YYYY-MM-DD"
                    )}?endDate=${moment(state.endDate).format(
                      "YYYY-MM-DD"
                    )}&paymentType=${state.paymentType}`
                  );
                }
              }}
            ></Checkbox>
            <p style={{ marginLeft: 10 }}>De plată</p>
          </div>
        </Grid>
        <Grid item xs={4} md={6} lg={6}>
          <StatusOrder
            isOrder={false}
            status={state.filter}
            handleChangeStatus={handleChangeStatus}
            name="applyFilters"
            noFilter={{
              name: "Niciun filtru",
              color: theme.palette.primary?.main,
            }}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        title={Vocabulary.orders}
        data={state.orders}
        columns={requestHeaders}
        options={getTableOptions()}
        components={components}
      />
      {openDeleteModal ? (
        <OrderModal
          title={Vocabulary.deleteOrder}
          openModal={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          handleModifyValues={handleDelete}
        />
      ) : (
        ""
      )}
      {openImportExcelModal ? (
        <ImportExcelModal
          open={openImportExcelModal.open}
          range={openImportExcelModal.range}
          title={openImportExcelModal.name}
          onClose={() =>
            setOpenImportExcelModal({
              open: false,
              range: 0,
              name: "",
            })
          }
          saveItems={(data: any) => getDataFromImportExcel(data)}
        />
      ) : null}
      {loading ? (
        <div className="loading">
          <CircularProgress color="primary" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
